function DepartmentsSwiper(){

    var self = this;

    console.log("home NewsSwiper");

    var swiper = new Swiper('.swiper-departments', {

        navigation: {
            nextEl: '.next-button',
            prevEl: '.prev-button',
        },
        slidesPerView: 3,
        centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 30,
        loop : true,

        breakpoints: {
            1200: {
                slidesPerView: 2.5,
                spaceBetween: 30
            },
            992: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            576: {
                slidesPerView: 1,
                spaceBetween: 60
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 60
            }
        }

    });

}
