function Gallery(){

    var self = this;

   console.log("----------------------- post gallery");

    var id =  $( ".post-gallery" ).attr('id');

    console.log("post gallery using window ID " + id);

    var postID = "#" + id;

    console.log("postID " + postID);

    var top = "#" + id + " .gallery-top";
    var thumbs = "#" + id + " .gallery-thumbs";

    console.log(top);

   if( $(".post-gallery").length)
    {

        self.galleryTop = new Swiper(top, {
            //nextButton: '.swiper-button-next',
            //prevButton: '.swiper-button-prev',
            zoom:true,
            spaceBetween: 10,
            effect: 'fade'
        });
        self.galleryThumbs = new Swiper(thumbs, {
            spaceBetween: 10,
            centeredSlides: true,
            slidesPerView: 'auto',
            touchRatio: 0.2,
            slideToClickedSlide: true
        });
        self.galleryTop.controller.control = self.galleryThumbs;
        self.galleryThumbs.controller.control = self.galleryTop;

    }
}
