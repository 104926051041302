function isSessionStorageSupported() {
  try {
    var storage = window.sessionStorage;
    storage.setItem('test', 'test');
    storage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  document.cookie = name + '=; Max-Age=-99999999;';
}

function Cookie() {

  if (isSessionStorageSupported()) {

    var amicoCookies = sessionStorage.getItem('amico-cookies');

    if (amicoCookies && amicoCookies === 'accepted') {
      TweenMax.set(".footer-notification", {autoAlpha: 0});
    }

    $(".close-notifcation").click(function (e) {

      console.log("close notifcation");
      e.preventDefault();

      TweenMax.to(".footer-notification", 0.5, {autoAlpha: 0});

      sessionStorage.setItem('amico-cookies', 'accepted');

    });

  } else {

    var x = getCookie('amico-cookies');
    if (x) {
      TweenMax.set(".footer-notification", {autoAlpha: 0});
    }

    $(".close-notifcation").click(function (e) {

      console.log("close notifcation");
      e.preventDefault();

      TweenMax.to(".footer-notification", 0.5, {autoAlpha: 0});

      setCookie("amico-cookies", "accepted cookies", 1);

    });

  }

}
