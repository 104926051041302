function AmenitiesMap(){

    var pointArray =[];
    var markers = [];

   // console.log("Map load via ajax call function");

    //console.log(data.apiLoaded);

    console.log("---------------------------- Map");

    function startMap() {

        var mapOptions;
        var MapPoints;
        var Zoom;

        var useCenter = $("#amenities-map").attr("data-center");

        console.log("use center", useCenter);

        if (useCenter) {

            console.log("set center using lat lon");

            var lat = $("#amenities-map").attr("data-zoom");
            var lon = $("#amenities-map").attr("data-zoom");

            MapPoints = new google.maps.LatLng(lat,lon);
            Zoom = $("#amenities-map").attr("data-zoom");

        } else {

            console.log("center around points");

            MapPoints = new google.maps.LatLng(0,0);
            Zoom = 3;
        }

        mapOptions = {
            zoom: Zoom,
            center: MapPoints,
            disableDefaultUI: true,
            panControl: false,
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL,
                position: google.maps.ControlPosition.RIGHT_BOTTOM
            },
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'blue']
            },
            scaleControl: false,
            //draggable: false,
            scrollwheel: false,




        };

        var  map = new google.maps.Map(document.getElementById('map'), mapOptions);

        var markerBounds = new google.maps.LatLngBounds();


        function addMapMarker(m){

            var c = m.colour;

            var square = {
                path: 'M 0,17 17,0 0,-17 -17,0 z',
                fillColor: c,
                fillOpacity: 1,
                scale: 1,
                //strokeColor: 'gold',
                strokeWeight: 0
            };

            var myMarker = new google.maps.Marker({
                position: m.points,
                map: map,
                title: m.name,
                //label: m.label,
                icon: square,

                label: {
                    text: m.label,
                    color: 'white',
                    weight:'bold'
                }

                //icon: image

            });

            var contentString = '<div id="content">'+
                '<h1>' + m.name +'</h1>'+
                '<div id="bodyContent">'+
                '<p>' + m.description + '</p>'+
                '<a href="'+ m.url +'" target="_blank">Visit website</a> '+
                '</div>'+
                '</div>';


            markerBounds.extend(m.points);

            markers.push(myMarker);

            var infoWindow = new google.maps.InfoWindow({
                content:  contentString
            });

            google.maps.event.addListener(myMarker, 'click', function() {
                infoWindow.open(map,myMarker);
            });

            //markerBounds.extend(mp);

        }

        $( ".map-link" ).each(function( index ) {

            var p = {};

            p.lat = $(this).attr("data-lat");
            p.lon = $(this).attr("data-lon");
            p.name = $(this).attr("data-name");
            p.description = $(this).attr("data-description");
            p.url = $(this).attr("data-url");
            p.count = $(this).attr("data-count");
            p.colour = $(this).attr("data-colour");

            console.log("p", p);

            pointArray.push(p);

        });


        if(pointArray.length>0){

            for(var i=0; i< pointArray.length; i++){

                var m = {};

                //var label = i + 1;

                //label = label.toString();

                console.log("label " + i);

                m.points = new google.maps.LatLng(pointArray[i].lat, pointArray[i].lon);
                m.name = pointArray[i].name;
                m.description = pointArray[i].description;
                m.url = pointArray[i].url;
                m.label = pointArray[i].count;
                m.colour = pointArray[i].colour;

                addMapMarker(m);

            }

        }

        if (useCenter) {


        } else {

            if (markers.length ===1){

            } else {

                map.fitBounds(markerBounds);
            }
        }

        $( ".map-link" ).each(function( index ) {

            $(this).click(function (e){

                e.preventDefault();

                var target = "#map";

                TweenMax.to(window, 1, {scrollTo: {y: target, autoKill:false}, ease:Power2.easeInOut});

                google.maps.event.trigger(markers[index], 'click');

            });


        });


    }

    function runAPITimercheck(){

        console.log("runAPITimercheck");

        console.log(window.data.apiLoaded);

        if (window.data.apiLoaded === true){

            console.log("run map");

            // initMap();

            startMap();

            //run map
        } else {

            console.log("check map API again");

            // / setTimeout(runAPITimercheck, 500);
        }

    }

    runAPITimercheck();

}
