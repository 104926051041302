function ScrollLinks(){

    var self = this;

    console.log("ScrollLinks");

    self.$link = $(".scroll-link");

    var controller = new ScrollMagic.Controller();

    controller.scrollTo(function (newpos){

        console.log("newpos " + newpos);

        TweenMax.to(window, 1, {scrollTo: {y: newpos}, ease:Power2.easeInOut});

    });

    self.$link.on("click", function (e) {

        console.log("click");

        e.preventDefault();

        var target = $(this).attr('href');

        controller.scrollTo(target);


        if (window.history && window.history.pushState) {
            history.pushState("", document.title, target);
        }

    });

    /*$(document).on("click", "a[href^='#']", function (e) {
        var id = $(this).data( "target");
        if ($(id).length > 0) {
            e.preventDefault();

            // trigger scroll
            controller.scrollTo(id);

            // if supported by the browser we can even update the URL.
            if (window.history && window.history.pushState) {
                history.pushState("", document.title, id);
            }
        }
    });*/


    /*$(document).on("click", "a[href^='#']", function (e) {
        var id = $(this).attr("href");
        if ($(id).length > 0) {
            e.preventDefault();

            // trigger scroll
            controller.scrollTo(id);

            // if supported by the browser we can even update the URL.
            if (window.history && window.history.pushState) {
                history.pushState("", document.title, id);
            }
        }
    });*/

}


