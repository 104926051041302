function Galleries(){

    var self = this;

   console.log("gallery");

    $( ".master-gallery" ).each(function( index ) {

        var thumbSwiperID = "#t" + index;
        var gallerySwiperID = "#g" + index;


        var galleryTop = new Swiper(gallerySwiperID, {
            //nextButton: '.swiper-button-next',
            //prevButton: '.swiper-button-prev',
            zoom:true,
            spaceBetween: 10,
            effect: 'fade'
        });
        var galleryThumbs = new Swiper(thumbSwiperID, {
            spaceBetween: 10,
            centeredSlides: true,
            slidesPerView: 'auto',
            touchRatio: 0.2,
            slideToClickedSlide: true
        });
        galleryTop.controller.control = galleryThumbs;
        galleryThumbs.controller.control = galleryTop;


    });

}
