function ContactMap(){

    console.log("---------------------------- ContactMap");



    function startMap() {

        var contactMapOptions;
        var contactMapPoints;
        var contactZoom = parseFloat($("#contact-map-wrapper").attr("data-zoom"));

        var contactLatCentre = parseFloat($("#contact-map-wrapper").attr("data-centre-lat"));
        var contactLonCentre = parseFloat($("#contact-map-wrapper").attr("data-centre-lon"));

       // console.log("coord "+ latCentre + " " + lonCentre);

        contactMapPoints = new google.maps.LatLng(contactLatCentre,contactLonCentre);

       // var lat = $("#contact-map-wrapper").attr("data-lat");
       // var lon = $("#contact-map-wrapper").attr("data-lat");

        contactMapOptions = {
            zoom: contactZoom,
            center: contactMapPoints,
            disableDefaultUI: true,
            panControl: false,
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL,
                position: google.maps.ControlPosition.RIGHT_BOTTOM
            },
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'blue']
            },
            scaleControl: false,
            //draggable: false,
            scrollwheel: false,


        };


      var contactMap = new google.maps.Map(document.getElementById('contact-map'), contactMapOptions);

      var pinSVGFilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
      var pinColor = "#015391";

      var markerImage = {  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
        path: pinSVGFilled,
        anchor: new google.maps.Point(12,17),
        fillOpacity: 1,
        fillColor: pinColor,
        strokeWeight: 1,
        strokeColor: "white",
        scale: 2,
      };


      var myMarker = new google.maps.Marker({
        position: contactMapPoints,
        map: contactMap,
        icon: markerImage,
      });

      myMarker.setMap(contactMap);

    }

    function runAPITimercheck(){

        console.log("runAPITimercheck");

        console.log(window.data.apiLoaded);

        if (window.data.apiLoaded === true){

            console.log("run map");

            // initMap();

            startMap();

            //run map
        } else {

            console.log("check map API again");

            // / setTimeout(runAPITimercheck, 500);
        }

    }

    runAPITimercheck();

}
