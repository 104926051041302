function Videos(){

    var self = this;

    var active = 0;

    self.animating = false;

   console.log("home Videos");

    //You will probably want to change 'li' to a more specific selector e.g. #navTab
    /*$('.nav-link').on('click', function() {

        var ID = $(this).attr('href');
        var src = $(this).attr('data-src');

        console.log("src ", src);

        //var iFrame = ID + " iframe";

        // $(iFrame).attr('src', '');

        $( ".tab-pane iframe" ).each(function( index ) {

            $(this).attr('src', '');

        });

        //var ID = $(this).attr('href');
        var iFrame = ID + " iframe";
        $(iFrame).attr('src', src);

    });*/

    $(".video-button").each(function( index ) {

        $(this).click(function (){

            console.log("click");

            var oldVideoPanel = "#video-"+ active;
            var src = $(this).attr('data-src');
            var ID = $(this).attr('data-target');

            var newIframe = ID + " iframe";
            //var src = $(this).attr('data-src');
            //var ID = $(this).attr('href');

            function switchFinished(){

                self.animating = false;
            }

            function switchVideos() {

                var oldIframe = oldVideoPanel + " iframe";

                $(oldIframe).attr('src', '');
                $(oldVideoPanel).removeClass("active");

                var columnContent = ID + " .column .row";
                var videoContent = ID + " .embed-container iframe";

                TweenMax.set(columnContent, {opacity: 0});
                TweenMax.set(videoContent, {opacity: 0});

                $(ID).addClass("active");

                $(newIframe).attr('src', src);

                TweenMax.to(columnContent, 0.5, {opacity: 1, onComplete:switchFinished});
                TweenMax.to(videoContent, 0.5, {opacity:1});

            }


            if (index !== active){

                if (!self.animating) {

                    console.log("switch videos");

                    self.animating = true;

                    $(newIframe).attr('src', '');

                    $(".video-button").each(function( index ) {

                        $(this).removeClass("active");

                    });

                    var columnContent = oldVideoPanel + " .column .row";
                    var videoContent = oldVideoPanel + " .embed-container iframe";

                    TweenMax.to(columnContent, 0.5, {opacity: 0});
                    TweenMax.to(videoContent, 0.5, {opacity: 0, onComplete: switchVideos});

                    active = index;


                } else {

                    console.log("still animating so dont switch videos");
                }
            }
        });
    });

   /* $( ".navigation-button" ).each(function( index ) {

        $(this).click(function (){

            var id = "#s" + index;

            if (id !== self.$new) {

                self.$previous = self.$new;

                self.$new = id;
                console.log(self.$new);

                TweenMax.set(self.$new, {opacity: 0});

                TweenMax.set(".slide", {css: {zIndex: self.allDepth}});
                TweenMax.set(self.$previous, {css: {zIndex: self.previousDepth}});
                TweenMax.set(self.$new, {css: {zIndex: self.newDepth}});

                TweenMax.to(self.$new, 0.5, {opacity: 1});

                var xPos = index * 25;
                var perc = xPos + "%";

                TweenMax.to($(".slide-marker"), 0.3, {left:perc});
            }

        });

    });*/
}
