function Facilities(){

    var self = this;

    self.imagesVisible =false;

    var active = 0;

    //self.size = new BrowserSize();

    self.animating = false;

    console.log("Videos Videos");

    $(".facilities-button").each(function( index ) {

        $(this).click(function (e){

            e.preventDefault();

            var target1 = "#tabs";

            TweenMax.to(window, 1, {scrollTo: {y: target1}, ease:Power2.easeInOut});

            console.log("facility clicked");

            $(".facilities-button").parent().removeClass("current-menu-item");


            $(this).parent().addClass("current-menu-item");

            console.log("click");

            var target2 = $(this).attr('href');

            console.log(target2);

            $(".facility-panel").removeClass("active");


            $(target2).addClass("active");

        });
    });
    $(".info-button").click(function( e ) {

        console.log("info-button-click");

        if($(this).hasClass("open")){

            $(this).removeClass("open");
            $(this).addClass("closed");

           TweenMax.to(".info", 0.5, {height:0});

           TweenMax.to(".text-box", 0.5, {opacity:1});

        } else {

            $(this).addClass("open");
            $(this).removeClass("closed");

            TweenMax.set(".info", {height:"auto"});
            TweenMax.from(".info", 0.5, {height:0});

            TweenMax.to(".text-box", 0.5, {opacity:0});

        }

    });

    $(".read-more-info").click(function( e ) {

        console.log("info-button-click");

        if($(".info-button").hasClass("open")){

            $(".info-button").removeClass("open");
            $(".info-button").addClass("closed");

            TweenMax.to(".info", 0.5, {height:0});

            TweenMax.to(".text-box", 0.5, {opacity:1});

        } else {

            $(".info-button").addClass("open");
            $(".info-button").removeClass("closed");

            TweenMax.set(".info", {height:"auto"});
            TweenMax.from(".info", 0.5, {height:0});

            TweenMax.to(".text-box", 0.5, {opacity:0});

        }

    });





    var appendNumber = 4;
    var prependNumber = 1;
    var facilitiesSwiper = new Swiper('.facilities-swiper', {
        slidesPerView: 1,
       // centeredSlides: true,
        zoom: true,
        //spaceBetween: 30,
        //pagination: {
         //   el: '.swiper-pagination',
         //   clickable: true,
        //},
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
    /*document.querySelector('.prepend-2-slides').addEventListener('click', function (e) {
        e.preventDefault();
        swiper.prependSlide([
            '<div class="swiper-slide">Slide ' + (--prependNumber) + '</div>',
            '<div class="swiper-slide">Slide ' + (--prependNumber) + '</div>'
        ]);
    });
    document.querySelector('.prepend-slide').addEventListener('click', function (e) {
        e.preventDefault();
        swiper.prependSlide('<div class="swiper-slide">Slide ' + (--prependNumber) + '</div>');
    });
    document.querySelector('.append-slide').addEventListener('click', function (e) {
        e.preventDefault();
        swiper.appendSlide('<div class="swiper-slide">Slide ' + (++appendNumber) + '</div>');
    });
    document.querySelector('.append-2-slides').addEventListener('click', function (e) {
        e.preventDefault();
        swiper.appendSlide([
            '<div class="swiper-slide">Slide ' + (++appendNumber) + '</div>',
            '<div class="swiper-slide">Slide ' + (++appendNumber) + '</div>'
        ]);
    });*/

    $(".image-button").click(function( index ) {

        // var images =  $(this).attr('data-images');



        var images =  $(this).data('images');

        console.log("image button");
        console.log(images);

        var parsed = jQuery.parseJSON(images);

        console.log(parsed);

        if(facilitiesSwiper) {

            facilitiesSwiper.removeAllSlides();
        }

        for (var i = 0; i < parsed.length; i++) {

            var count = i +1;
            var length = parsed.length;

            console.log("i " ,i);
            /*facilitiesSwiper.appendSlide(['<div class="swiper-slide"><div class="slide-content"></div></div>']);*/

            facilitiesSwiper.appendSlide(['<div class="swiper-slide"><div class="slide-content"><div class="image-wrapper"><img' +
            ' alt="' + parsed[i].alt + '" src="' + parsed[i].src + '"></div><div class="image-caption">' + count + '/' + length + " " + parsed[i].caption + '</div></div></div>']);


           /* facilitiesSwiper.appendSlide(['<div class="swiper-slide"><div class="slide-content"><div class="image-wrapper"><img' +
            ' alt="' + parsed[i].alt + '" src="' + parsed[i].src + '"></div><div class="image-caption">' + count + '/' + length + " " + parsed[i].caption + '</div></div></div>']);*/

          /*  facilitiesSwiper.appendSlide(['<div class="swiper-slide"><div class="swiper-zoom-container">' + '<img' +
            ' alt="' + parsed[i].alt + '" src="' + parsed[i].src + '"><div class="caption">some caption</div></div></div></div>']);*/
        }

        TweenMax.set(".facilities-modal", {autoAlpha:1});

        self.imagesVisible= true;

        $('body').addClass("no-scroll");

        var x = $(".image-wrapper img").position();
        console.log("position " + x.top + " " + x.left);

    });

    function closeImages(){

        console.log("close");

        $('body').removeClass("no-scroll");

        TweenMax.set(".facilities-modal", {autoAlpha:0});

        self.imagesVisible = false;


    }

   // if( self.imagesVisible){

        $(document).on('keyup',function(evt) {

            console.log("key up");

            if( self.imagesVisible) {
                if (evt.keyCode === 27) {

                    closeImages();
                    // alert('Esc key pressed.');
                }
            }
        });
   // }

    $(".facilities-modal-close").click(function( e ) {

        closeImages();

    });

    //$( window ).resize(function() {

       // var x = $(".image-wrapper img").position();
       // console.log("position " + x.top + " " + x.left);
        //$( "#log" ).append( "<div>Handler for .resize() called.</div>" );
   // });

}
