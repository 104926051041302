function Menu(){

    console.log("menu");

    var self = this;

    self.mobile = true;

    this.menuOpen = false;

    function hideMenu(){

        $('.menu-wrapper').removeClass("menu-open");
    }

    function closeMenu(){

        $('.sub-menu').removeClass('visible');

        function hideDropdown() {

            TweenMax.set(".background", {height: 0});

        }

        TweenMax.to(".overlay", 0.25, {autoAlpha: 0, onComplete: hideDropdown});

    }

   // TweenMax.set(".menu-wrapper",{top:-30});


    $('.main-navbar .navbar-toggle').click(function(e) {

       console.log('click');

       if(self.menuOpen){

           console.log("close");
           $('.navbar-toggle').removeClass("open");

           TweenMax.set(".main-navbar .navbar-toggle .open-menu", {css:{display:"block"}});
           TweenMax.set(".main-navbar .navbar-toggle .close-menu", {css:{display:"none"}});

           TweenMax.to(".menu-wrapper", 0.5, {top:"-600", onComplete: hideMenu});
           TweenMax.to(".overlay", 0.25, {autoAlpha: 0});

       } else {

           console.log("open");

           $('.navbar-toggle').addClass("open");
           $('.menu-wrapper').addClass("menu-open");

           TweenMax.set(".menu-wrapper", {top:"-600"});
           TweenMax.to(".menu-wrapper", 0.5, {top:"0"});
           TweenMax.to(".overlay", 0.25, {autoAlpha: 1});
       }

        self.menuOpen =! self.menuOpen;

    });

    self.size = new BrowserSize();


    function checkSize(){

        //var self = this;

        self.s =  self.size.getWindowSize();

        //console.log("width " + self.s.width);

        if (self.s.width < 992){

            self.mobile = true;

            closeMenu();

        } else {

            self.mobile = false;
        }
    }

    checkSize();


    $(window).resize(function (){


        checkSize();

    });

    $('.main-navbar .menu-item-has-children > a').click(function(e) {

        var $link = $(this);

        function showDropdown(){

            var $sub = $link.siblings('.sub-menu');
            $sub.addClass('visible');

            TweenMax.set($sub, {autoAlpha: 0});
            TweenMax.to($sub, 0.3, {autoAlpha: 1});

        }

        if ($(this).parent().parent().hasClass("menu")){

            if (self.mobile === false){

                console.log("top level");

                e.preventDefault();

                if (!$link.siblings('.sub-menu').hasClass('visible')) {

                    $('.main-navbar .menu-item-has-children a').removeClass('sub-open');

                    $link.addClass('sub-open');

                    console.log("sub menu open");

                    $('.sub-menu').removeClass('visible');

                    TweenMax.set(".background", {height: 0});
                    TweenMax.set(".overlay", {css: {"visibility": "visible"}});
                    TweenMax.to(".overlay", 0.3, {autoAlpha: 1});
                    TweenMax.to(".background", 0.4, {height: 430, delay: 0.3, onComplete:showDropdown});
                }


            }else {




            }


        }

    });

    $('.sub-menu .sub-menu a').click(function(e) {

        console.log("child in sub menu");

        closeMenu();

    });

    $('.overlay').click(function(e) {

       closeMenu();

    });

}
