function Statistics() {

    console.log("Sponsors");

    var self = this;

    $("#stat-1").html('1');
    $("#stat-2").html('1');
    $("#stat-3").html('1');
    $("#stat-4").html('1');
    $("#stat-5").html('1');
    $("#stat-6").html('1');

    self.controller = new ScrollMagic.Controller();

    var targetArray = [];

    $( ".stat" ).each(function( index ) {


        var target = $(this).attr("data-target");

        targetArray.push(target);

    });


    self.d1 = {number:1};
    self.d2 = {number:1};
    self.d3 = {number:1};
    self.d4 = {number:1};
    self.d5 = {number:1};
    self.d6 = {number:1};

    function numberUpdate() {

       // console.log("number " +self.d1.number);

        $("#stat-1").html(Math.ceil(self.d1.number));
        $("#stat-2").html(Math.ceil(self.d2.number));
        $("#stat-3").html(Math.ceil(self.d3.number));
        $("#stat-4").html(Math.ceil(self.d4.number));
        $("#stat-5").html(Math.ceil(self.d5.number));
        $("#stat-6").html(Math.ceil(self.d6.number));

       // $("#s1").text(self.d1.number);

        //var num = commaSeparateNumber(Math.floor(self.data.number));

        //self.$number.html("$" + num);

    }




    var numberTween = new TimelineMax({onUpdate:numberUpdate})

        .to(self.d1, 1, {number:targetArray[0]})
        .to(self.d2, 1, {number:targetArray[1]})
        .to(self.d3, 1, {number:targetArray[2]})
        .to(self.d4, 1, {number:targetArray[3]})
        .to(self.d5, 1, {number:targetArray[4]})
        .to(self.d6, 1, {number:targetArray[5]})

    ;

        var scene = new ScrollMagic.Scene({triggerElement: "#trigger", reverse: false})
            .setTween(numberTween)
            .addTo(self.controller);
    //var scene = new ScrollMagic.Scene({triggerElement: "#trigger", duration: 1500, offset: -350})
      //  .setTween(numberTween)
        //.addIndicators({name: "tween css class"}) // add indicators (requires plugin)
      //  .addTo(self.controller);




}//
