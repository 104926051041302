function TabMenu(container){

    function checkSize() {

        console.log("----- check size menu-wrapper");

        var tabWidth = $(".menu-wrapper", ".tab-navigation").width();
        var childWidth = 0;
        var width = 0;

        if (container){

            $(".tab-navigation li", container).each(function (index) {

                width = $(this).innerWidth();
                console.log("tab");
                childWidth += width;
                childWidth += 4;

            });

        }else {

            $(".tab-navigation li").each(function (index) {

                width = $(this).innerWidth();
                console.log("tab");
                childWidth += width;
                childWidth += 4;

            });

        }


        console.log("tabWidth " + tabWidth);
        console.log("childWidth " + childWidth);

        if (childWidth > tabWidth) {

            $(".tab-navigation .more-arrow").addClass("visible");
            $(".tab-navigation .menu-wrapper").addClass("show-more");

            console.log("show more");
        } else {

            $(".tab-navigation .more-arrow").removeClass("visible");
            $(".tab-navigation .menu-wrapper").removeClass("show-more");

            console.log("hide more");
        }

        $('.more-arrow').click(function(event) {

            var selector = $(".tab-navigation ul");

            var offset = selector.scrollLeft();

            console.log("offset "+ offset);

            TweenMax.to(selector, 0.5, {scrollTo: {x: offset + 100 }, ease:Power2.easeInOut});

        });

    }

    checkSize();

    $(window).resize(function (){


        checkSize();

    });

}
