function FeaturedPanels(){

    var self = this;

    var active = 0;

    self.animating = false;

    console.log("Videos Videos");

    //$(".box").each(function( index ) {

    $(".box-0").click(function( e ) {

            console.log(".link-0 click");

            $(".link-0")[0].click();

        });

    $(".box-1").click(function( e ) {

        console.log(".link-1 click");

        $(".link-1")[0].click();

    });

    $(".box-2").click(function( e ) {

        console.log(".link-2 click");

        $(".link-2")[0].click();

    });

    $(".box-3").click(function( e ) {

        console.log(".link-3 click");

        $(".link-3")[0].click();

    });


    //});




}
